import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        tab:0,
        version:"v1.121124",  //version number, day, month
        cupon:null,
        net_pay_token:"",
        loggedin: false,
        token: "",
        user: {},
        menu_inferior: 1,
        title: "",
        page: 0,
        home: false,
        events: [],
        coupons_total: 0,
        user_vehicles: [],
        appointments: []
    },
    mutations: {
        addEvent(state, event) {
            state.events.unshift(event)
        },
        addAppointment(store, event) {
            store.appointments.unshift(event)
        },
        logout(state) {
            state.loggedin = false
        },
        setMenuInferior(state, value) {
            state.menu_inferior = value;
        }
    }
})
export default store