<template>
<div class="main">
    <!-- Botón invitar amigos-->
    <v-row justify="center" class="pt-5">
        <v-btn elevation="4" class="eps_green boton_eps" @click="share()">
            <v-icon class="pr-2">mdi-email-fast-outline</v-icon>Invitar a amigos
        </v-btn>
    </v-row>
    <v-row align="center" justify="center" class="pt-1 pb-2">
        <v-card width="90%">
            <v-card-title primary-title>
                Cupones
            </v-card-title>
            <v-card-text>
                Invita a amigos y familiares a unirse a EPS+ y recibe cupones de descuento para tu siguiente visita a EPS
            </v-card-text>
        </v-card>
    </v-row>
    <v-row align="center" justify="center" class="mb-10 pb-10">
        <v-card  v-for="cupon in cupones" :key="cupon.name" width="90%" elevation="6" border="6" :class="cupon.state=='Valido' ? 'cupon_valid' : 'cupon_invalid'">
            <v-card-title primary-title>
                <v-icon color="green" class="pr-4">mdi-gift</v-icon>
                <h4>{{ cupon.name }}</h4>
                <v-spacer></v-spacer>
                <h4> {{ cupon.amount |MXN }}</h4>
            </v-card-title>
            <v-card-text>
                <v-row>Valor: {{cupon.amount | MXN}}</v-row>
                <v-row>Vigencia: {{cupon.valid_from | date_format_short}} al {{cupon.valid_until | date_format_short}} </v-row>
                <v-row>Válido en: {{cupon.shop_name}}
                    <span v-if="cupon.shop_name==null">  &nbsp;Talleres participantes</span>
                </v-row>
                <v-row>Estado: {{cupon.state}}</v-row>
                <v-row v-if="cupon.state=='Pendiente'" align="center" justify="center">
                    <p v-if="cupon.name=='Cupon Referido'" class="aviso">Aviso: Tu cupón no está activo. Puedes recordarle a tus invitados que contrates el Plan EPS+ para activar este cupón.</p>
                    <p v-if="cupon.name=='Cupon EPS+'" class="aviso">Aviso: Tu cupón no está activo. Puedes recordarle a tus invitados que contrates el Plan EPS+ para activar este cupón.</p>
                </v-row>
                <v-row align="center" justify="center">
                    <p class="condiciones">Condiciones: {{cupon.restrictions}}</p>
                </v-row>
            </v-card-text>
        </v-card>
    </v-row>

  
    <!--General Alert-->
    <v-snackbar color="green" timeout="8000" v-model="alert" class="pa-8">
        <h3>Atención:</h3>
        <strong>{{mensaje_de_invitacion}}</strong>
        <v-col>
            <v-row justify="end">
                <v-btn elevation="4" text @click="alert=false">Ok</v-btn>
            </v-row>
        </v-col>
    </v-snackbar>
</div>
</template>

<script>
import axios from "axios"
export default {
    name: 'MisCupones',
    data() {
        return {
            shareData: {
                text: "¡Te invito a descargar la app EPS+ totalmente gratis!\n\n" +
                      "Con EPS+ podrás administrar los servicios de tu automóvil.\n\n" +
                      "Al contratar un Plan EPS+ obtendrás:\n" +
                      "✓ Cambios de aceite sintético ilimitados\n" +
                      "✓ Asistencia y seguros viales\n\n" +
                      "¡Y además, al unirte al Plan EPS+ obtienes un cupón de $200.00 de descuento en tu siguiente servicio!\n\n" +
                      "Sigue esta liga para crear tu cuenta totalmente gratis y obtén tu cupón de descuento: https://epsapp.prosperus.network?cupon=" + this.$store.state.user.inviter_token,
            },
            alert: false,
            mensaje_de_invitacion: "",
            email: "",
            show_invite_friend: false,
            friends_list: [],
            apiurl: process.env.VUE_APP_ROOT_API,
            cupones: [],
        }
    },
    mounted() {

    },
    methods: {
        async share() {
            try {
                await navigator.share(this.shareData);
                let text = "Shared successfully";
                console.log(text)
            } catch (err) {
                let error = `Error: ${err}`
                console.log(error)
            }
        },
        async get_coupons_total(user_id) {
            try {
                let response = await axios.post(this.apiurl + '/api/coupons/get_coupons_total_by_user_id', {
                    user_id: user_id
                })
                if (response.data.status == "ok") {
                    this.$store.state.coupons_total = response.data.coupons[0].coupons_total
                }
            } catch (err) {
                console.log(err)
            }
            try {
                let response = await axios.post(this.apiurl + '/api/coupons/get_coupons_by_user_id', {
                    user_id: user_id
                })
                if (response.data.status == "ok") {
                    this.$store.state.coupons = response.data.coupons
                }
            } catch (err) {
                console.log(err)
            }
        },
        async get_coupons(user_id) {
            try {
                let response = await axios.post(this.apiurl + '/api/coupons/get_coupons_by_user_id', {
                    user_id: user_id
                })
                if (response.data.status == "ok") {
                    this.cupones = response.data.coupons
                    this.$store.state.coupons = response.data.coupons
                    localStorage.setItem("coupons", JSON.stringify(response.data.coupons))
                }
            } catch (err) {
                console.log(err)
            }
        }

    },
    created() {
        this.$store.state.title = "Mis Cupones"
        this.get_coupons(this.$store.state.user.id)
        this.get_coupons_total(this.$store.state.user.id)

    },
    computed: {
        vehicle_image_path() {
            return process.env.VUE_APP_VEHICLE_IMG_PATH
        },
    }
};
</script>

<style scoped>
.condiciones {
    font-size: 0.75em;
    text-align: center;
    margin-top: 4px;
    color: rgb(2, 2, 78);
    margin-bottom: 0px;
    font-weight: bold;
}

.aviso {
    font-size: 0.8em;
    text-align: center;
    margin-top: 4px;
    color: rgb(237, 28, 36) !important;
    margin-bottom: 0px;
}

.cupon_valid {
    padding-left: 10px!important;
    border: solid;
    border-color: rgb(154, 202, 60) !important;
    background-color: #ffffffe1;
    border-radius: 25px 0px 25px 0;
    margin-bottom: 6px;
}

.cupon_invalid {
    padding-left: 10px!important;
    background-color: rgb(255, 255, 255);
    border: solid;
    border-color: rgb(237, 28, 36) !important;
    border-radius: 25px 0px 25px 0;
    margin-bottom: 6px;
}

.main {
    height: 100vmin;
}

.titulo {
    font-size: .8em;
}
</style>
