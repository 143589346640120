<template>
    <!-- Mi cuenta-->
    <div>
        <!-- Mis datos-->
        <v-row>
            <v-col class="pa-0 pt-4" align="center" width="100%">
                <!-- Mi perfil-->
                <v-card width="90%" class="pb-7">
                    <v-card-title>
                        <v-col>
                            <v-row justify="center">
                                <v-avatar size="100" class="avatar_img">
                                    <v-img :src="`${profile_image_path}`"></v-img>

                                </v-avatar>
                            </v-row>
                            <v-row justify="center">
                                <p v-if="($store.state.user.nombre != null)">
                                    {{ $store.state.user.nombre + " " + $store.state.user.apellido_p + " " + $store.state.user.apellido_m }}
                                </p>
                                <p v-else>Completa tu perfil...

                                </p>
                            </v-row>
                        </v-col>
                    </v-card-title>
                    <v-sheet class="mr-4 ml-4 pb-5">
                        <v-card-text>

                            <p class="mb-0">
                                <v-icon class="pr-2" color="blue">mdi-email</v-icon>{{ $store.state.user.username }}
                            </p>
                            <p class="mb-0">
                                <v-icon class="pr-2" color="blue">mdi-phone</v-icon>
                                <span v-if="$store.state.user.codigo_pais">
                                    {{ $store.state.user.codigo_pais + " " + $store.state.user.telefono_movil }}
                                </span>
                            </p>
                            <p class="mb-0">
                                <v-icon class="pr-2" color="blue">mdi-cake-variant</v-icon>
                                <span v-if="$store.state.user.fecha_de_nacimiento">{{
                                    $store.state.user.fecha_de_nacimiento
                                    | date_format_short}}</span>
                            </p>
                            <p v-if="$store.state.user.address_id">
                                <v-icon class="pr-2" color="blue">mdi-map-marker</v-icon>
                                {{ $store.state.user.calle }}
                                {{ $store.state.user.numero }}
                                {{ $store.state.user.numero_interior && $store.state.user.numero_interior.trim() ?
                                    ', Int. ' + $store.state.user.numero_interior : '' }}
                                <br>
                                {{ $store.state.user.colonia }}
                                {{ $store.state.user.municipio }}
                                {{ $store.state.user.ciudad }}
                                {{ $store.state.user.estado }}
                                {{ $store.state.user.codigo_postal ? ', CP ' + $store.state.user.codigo_postal : '' }}
                                <br>
                                {{ $store.state.user.pais }}
                            </p>

                        </v-card-text>
                        <v-card-actions>
                            <v-row align="center" justify="center" class="pa-4">

                                <v-btn small width="70%" class="eps_green" @click="open_edit_profile()">
                                    <v-icon left>mdi-pencil</v-icon>
                                    Editar perfil...
                                </v-btn>
                                <v-btn small width="70%" class="eps_green" @click="show_reset_password = true">
                                    <v-icon left>mdi-lock</v-icon>
                                    Cambiar contraseña...</v-btn>

                                <v-btn small width="70%" class="eps_red" @click="show_delete_account = true">
                                    <v-icon left>mdi-delete</v-icon>
                                    Eliminar mi cuenta...</v-btn>
                            </v-row>
                        </v-card-actions>
                    </v-sheet>
                </v-card>
            </v-col>
        </v-row>
        <!-- Alerta -->
        <v-snackbar timeout="8000" v-model="alert">
            <v-container fluid class="pa-2 ma-2" :class="alert_color">{{ alert_title }}</v-container>
            <v-container grid-list-xs>
                {{ alert_message }}
            </v-container>
            <v-row justify="end">
                <v-btn text @click="alert = false">Ok</v-btn>
            </v-row>
        </v-snackbar>
        <!-- Editar el perfil del usuario -->
        <v-dialog v-model="show_edit_profile">
            <v-card>
                <v-card-title primary-title>
                    Editar mi perfil:
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <v-text-field v-model="name" name="nombre" label="Nombre"
                            prepend-icon="mdi-account"></v-text-field>
                        <v-text-field v-model="last_name_p" name="last_name" label="Apellido paterno"
                            class="pl-8"></v-text-field>
                        <v-text-field v-model="last_name_m" name="last_name_m" label="Apellido materno"
                            class="pl-8"></v-text-field>
                        <v-file-input v-model="profile_pic" prepend-icon="mdi-camera" label="Fotografía" show-size
                            counter accept="image/x-png,image/gif,image/jpeg"></v-file-input>

                        <v-menu v-model="menu" :close-on-content-click="false" offset-y min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field prepend-icon="mdi-calendar" v-model="formattedBirthdate" readonly
                                    label="Fecha de nacimiento" v-bind="attrs" v-on="on" />
                            </template>
                            <v-date-picker v-model="birthdate" :max="maxDate" locale="es-MX"
                                @change="menu = false"></v-date-picker>
                        </v-menu>
                        <v-row align="center" class="mt-1">
                            <v-col cols="auto">
                                <v-icon>mdi-phone</v-icon>
                            </v-col>
                            <v-col cols="auto">
                                <vue-country-code class="mb-3" enabledCountryCode @onSelect="onSelect"
                                    :preferredCountries="['mx', 'us', 'ca']">
                                </vue-country-code>
                            </v-col>
                            <v-col>
                                <v-text-field v-model="telefono_movil" name="telefono_movil" label="Teléfono movil"
                                    class="mb-0"
                                    :rules="[
                                        v => /^\d{10}$/.test(v) || 'El teléfono debe contener exactamente 10 números'
                                    ]"
                                    @input="validatePhoneNumber"></v-text-field>
                            </v-col>
                        </v-row>

                        <!-- Address Selection Section -->
                        <div class="address-selection">
                            <h3 class="address-title">Dirección</h3>
                            <v-text-field ref="locationInput" v-model="address.calle" label="Calle"
                                id="location-input" prepend-icon="mdi-map-marker">
                            </v-text-field>

                            <v-row>
                                <v-col>
                                    <v-text-field v-model="address.numero" label="Número exterior"
                                        class="pl-8"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="address.numero_interior"
                                        label="Número interior"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-text-field v-model="address.colonia" label="Colonia"
                                class="pl-8"></v-text-field>
                            <v-text-field v-model="address.municipio" label="Municipio" class="pl-8"></v-text-field>

                            <v-row>
                                <v-col>
                                    <v-text-field v-model="address.estado" label="Estado"
                                        id="administrative_area_level_1-input" class="pl-8"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="address.codigo_postal" label="Código Postal"
                                        id="postal_code-input"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-text-field v-model="address.pais" label="País" id="country-input"
                                class="pl-8"></v-text-field>
                        </div>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="eps_red" @click="show_edit_profile = false">Cancelar</v-btn>
                    <v-btn :loading="saving_user_profile" class="eps_green"
                        @click="save_user_profile()">Guardar...</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Cambiar contraseña -->
        <v-dialog v-model="show_reset_password" scrollable max-width="90%" transition="dialog-transition">
            <v-card>
                <v-card-title primary-title>
                    Cambiar contraseña
                </v-card-title>
                <v-card-text>
                    Confirma tu correo y haz click en "Cambiar contraseña", te enviaremos un correo con instrucciones.
                </v-card-text>
                <v-card-text>
                    <v-text-field v-model="username" name="Correo" label="Usuario" id="id"></v-text-field>
                </v-card-text>
                <v-card-actions class="mb-6">
                    <v-col>
                        <v-row>
                            <p>{{ recover_message }}</p>
                        </v-row>
                        <v-row>

                            <v-btn small @click="recover_password()" class="eps_green" dark>Cambiar
                                contraseña...</v-btn>
                            <v-btn small @click="show_reset_password = false" class="eps_red">Cancelar</v-btn>
                        </v-row>
                    </v-col>
                </v-card-actions>
            </v-card>

        </v-dialog>
        <!-- Eliminar contraseña -->
        <v-dialog v-model="show_delete_account" scrollable max-width="90%" transition="dialog-transition">
            <v-card>
                <v-card-title primary-title>
                    ¡Es una pena que te vayas!
                </v-card-title>
                <v-card-text>
                    Al hacer click en "Eliminar mi cuenta" tus datos personales y tu cuenta en EPS+ se eliminarán de
                    nuestro
                    sistema.
                </v-card-text>

                <v-card-actions class="mb-6">
                    <v-col>
                        <v-row>
                            <p>{{ recover_message }}</p>
                        </v-row>
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-btn small @click="show_delete_account = false" class="eps_red">Cancelar</v-btn>
                            <v-btn small @click="delete_account()" class="eps_green" dark>Eliminar mi cuenta</v-btn>
                        </v-row>
                    </v-col>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </div>
</template>

<script>
import axios from "axios"
import supabaseMixin from '../mixins/SupabaseMixin.js'

export default {
    name: "MiCuenta",
    mixins: [supabaseMixin],
    data: () => ({
        name: "",
        last_name_p: "",
        last_name_m: "",
        birthdate: null,
        telefono_movil: "",
        country_code: "",
        profile_pic: null,

        menu: false,
        show_edit_profile: false,
        show_reset_password: false,
        show_delete_account: false,
        saving_user_profile: false,

        alert: false,
        alert_message: "",
        alert_title: "",
        alert_color: "",
        recover_message: "",

        username: "",
        apiurl: process.env.VUE_APP_ROOT_API,
        address: {
            calle: "",
            numero: "",
            numero_interior: "",
            colonia: "",
            municipio: "",
            estado: "",
            codigo_postal: "",
            pais: ""
        },

        googleMapsLoaded: false,
        maxDate: new Date().toISOString().substr(0, 10), // Today's date as max
    }),
    methods: {
        logout() {
            // Reset all store state values to initial values
            this.$store.state.tab = 0
            this.$store.state.cupon = null
            this.$store.state.net_pay_token = ""
            this.$store.state.loggedin = false
            this.$store.state.token = ""
            this.$store.state.user = {}
            this.$store.state.menu_inferior = 1
            this.$store.state.title = ""
            this.$store.state.page = 0
            this.$store.state.home = false
            this.$store.state.events = []
            this.$store.state.coupons_total = 0
            this.$store.state.user_vehicles = []
            this.$store.state.appointments = []
            
            // Clear local storage
            localStorage.clear()
        },
        async delete_account() {
            let response = await axios.post(this.apiurl + '/api/clients/delete_account', {
                email: this.$store.state.user.username,
            })
            if (response.data.status == "ok") {
                this.logout()
            }
        },
        async recover_password() {
            if (this.username == "") {
                this.alert_color = "red"
                this.alert_title = "Error"
                this.alert_message = "Ingrese el correo con el que ingresas a EPS+"
                this.alert = true
                return
            } else {
                let recover = await axios.post(this.apiurl + '/api/clients/recover_password', {
                    username: this.username,
                })
                if (recover.data.status == "ok") {
                    this.alert = true
                    this.alert_color = "green"
                    this.alert_title = "Revisa tu email..."
                    this.alert_message = "Te enviamos un correo con instrucciones para reestablecer tu contraseña..."
                    this.show_reset_password = false
                } else if (recover.data.status == "error") {
                    this.recover_message = recover.data.message
                }
            }
        },
        onSelect({
            name,
            iso2,
            dialCode
        }) {
            this.country_code = dialCode
            console.log(name, iso2, dialCode);
        },
        loadGoogleMapsScript() {
            return new Promise((resolve, reject) => {
                if (window.google) {
                    resolve();
                    return;
                }

                // Create callback function to be called when Maps API loads
                window.initGoogleMaps = () => {
                    this.googleMapsLoaded = true;
                    resolve();
                };

                // Create and append the script tag
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initGoogleMaps&loading=async`;
                script.async = true;
                script.onerror = reject;
                document.head.appendChild(script);
            });
        },
        async initMap() {
            try {
                // Wait for Google Maps to load if it hasn't already
                if (!this.googleMapsLoaded) {
                    await this.loadGoogleMapsScript();
                }

                // Wait for the next tick to ensure the ref is available
                await this.$nextTick();

                // Check if the ref exists
                if (!this.$refs.locationInput || !this.$refs.locationInput.$el) {
                    console.warn('Location input ref not found');
                    if (this.show_edit_profile) {
                        setTimeout(() => this.initMap(), 100);
                    }
                    return;
                }

                const autocomplete = new window.google.maps.places.Autocomplete(
                    this.$refs.locationInput.$el.querySelector('input'),
                    {
                        fields: ['address_components', 'geometry', 'name'],
                        types: ['address'],
                        componentRestrictions: { country: 'mx' },
                    }
                );

                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    if (!place.geometry) {
                        window.alert(`No hay detalles disponibles para: '${place.name}'`);
                        return;
                    }
                    this.fillInAddress(place);
                });
            } catch (error) {
                console.error('Error initializing Google Maps:', error);
            }
        },
        open_edit_profile() {
            this.show_edit_profile = true;
            this.name = this.$store.state.user.nombre;
            this.last_name_p = this.$store.state.user.apellido_p;
            this.last_name_m = this.$store.state.user.apellido_m;
            this.country_code = this.$store.state.user.codigo_pais;
            this.telefono_movil = this.$store.state.user.telefono_movil;
            this.colonia = this.$store.state.user.colonia;
            this.municipio = this.$store.state.user.municipio;
            this.estado = this.$store.state.user.estado;
            this.codigo_postal = this.$store.state.user.codigo_postal;
            this.pais = this.$store.state.user.pais;
            this.calle = this.$store.state.user.calle;
            this.numero = this.$store.state.user.numero;

            // Handle the birthdate
            if (this.$store.state.user.fecha_de_nacimiento) {
                this.birthdate = this.$store.state.user.fecha_de_nacimiento.split('T')[0];
            } else {
                this.birthdate = null;
            }
            
            // Fixed address initialization matching the exact model structure
            this.address = {
                calle: this.$store.state.user.calle || '',
                numero: this.$store.state.user.numero || '',
                numero_interior: this.$store.state.user.numero_interior || '',
                colonia: this.$store.state.user.colonia || '',
                municipio: this.$store.state.user.municipio || '',
                estado: this.$store.state.user.estado || '',
                codigo_postal: this.$store.state.user.codigo_postal || '',
                pais: this.$store.state.user.pais || '',
                id: this.$store.state.user.address_id
            };

            // Initialize map after dialog is shown
            this.$nextTick(() => {
                this.initMap();
            });
        },
        async resizeImage(file) {
            return new Promise((resolve) => {
                // Create an image element
                const img = new Image();
                img.src = URL.createObjectURL(file);
                
                img.onload = () => {
                    // Max dimensions
                    const MAX_WIDTH = 500;
                    const MAX_HEIGHT = 500;
                    
                    let width = img.width;
                    let height = img.height;
                    
                    // Calculate new dimensions while maintaining aspect ratio
                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }
                    
                    // Create canvas and resize
                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
                    
                    // Convert to blob
                    canvas.toBlob((blob) => {
                        resolve(new File([blob], file.name, {
                            type: 'image/jpeg',
                            lastModified: Date.now()
                        }));
                    }, 'image/jpeg', 0.8); // 0.8 quality
                };
            });
        },
        async save_user_profile() {
            this.saving_user_profile = true;
            let profile_pic_name = "";

            try {
                // Handle profile picture
                if (this.profile_pic) {
                    profile_pic_name = this.profile_pic ?
                    Date.now() + "_" + this.profile_pic.name :
                    this.$store.state.user.foto || "";
                    // Resize image before upload
                    const resizedImage = await this.resizeImage(this.profile_pic);
                    await this.upload_profile_image(profile_pic_name, resizedImage);
                }

                // Prepare payload with nested address object
                const payload = {
                    client_id: this.$store.state.user.id,
                    nombre: this.name || "",
                    apellido_p: this.last_name_p || "",
                    apellido_m: this.last_name_m || "",
                    fecha_de_nacimiento: this.birthdate || this.$store.state.user.fecha_de_nacimiento || null,
                    foto: profile_pic_name,
                    codigo_pais: this.country_code || "",
                    telefono_movil: this.telefono_movil || "",
                    address: {
                        calle: this.address.calle || "",
                        numero: this.address.numero || "",
                        numero_interior: this.address.numero_interior || "",
                        colonia: this.address.colonia || "",
                        municipio: this.address.municipio || "",
                        estado: this.address.estado || "",
                        codigo_postal: this.address.codigo_postal || "",
                        pais: this.address.pais || "",
                        id: this.address.id || this.$store.state.user.address_id
                    }
                };

                const response = await axios.post(this.apiurl + '/api/clients/edit', payload);
                console.log(response.data);

                if (response.data.status == "ok") {
                    this.$store.state.user = response.data.user;
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    this.saving_user_profile = false;
                    this.show_edit_profile = false;
                }
            } catch (err) {
                console.error('Error saving profile:', err);
                // You might want to show an error message to the user here
                this.alert = true;
                this.alert_color = "red";
                this.alert_title = "Error";
                this.alert_message = "Hubo un error al guardar el perfil. Por favor intente nuevamente.";
            } finally {
                this.saving_user_profile = false;
                this.show_edit_profile = false;
            }
        },

        fillInAddress(place) {
            // Reset address fields to avoid stale data
            this.address = {
                calle: '',
                numero: this.address.numero,
                numero_interior: this.address.numero_interior,
                colonia: '',
                municipio: '',
                estado: '',
                codigo_postal: '',
                pais: '',
                id: this.address.id
            };

            // Process each address component
            place.address_components.forEach(component => {
                const type = component.types[0];
                
                switch(type) {
                    case 'street_number':
                        this.address.numero = component.long_name;
                        break;
                    case 'route':
                        this.address.calle = component.long_name;
                        break;
                    case 'sublocality_level_1':
                        this.address.colonia = component.long_name;
                        break;
                    case 'locality':
                        this.address.municipio = component.long_name;
                        break;
                    case 'administrative_area_level_1':
                        this.address.estado = component.long_name;
                        break;
                    case 'postal_code':
                        this.address.codigo_postal = component.long_name;
                        break;
                    case 'country':
                        this.address.pais = component.long_name;
                        break;
                }
            });
        },
        formatDate(dateString) {
            if (!dateString) return '';

            try {
                // Check if dateString is already in YYYY-MM-DD format
                if (dateString.includes('T')) {
                    dateString = dateString.split('T')[0];
                }

                const [year, month, day] = dateString.split('-').map(Number);

                // Validate date components
                if (!year || !month || !day) return '';

                const months = [
                    'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
                    'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
                ];

                return `${day} de ${months[month - 1]} de ${year}`;
            } catch (error) {
                console.error('Error formatting date:', error);
                return '';
            }
        },
        validatePhoneNumber(value) {
            // Remove any non-digit characters
            this.telefono_movil = value.replace(/\D/g, '');
            
            // Limit to 10 digits
            if (this.telefono_movil.length > 10) {
                this.telefono_movil = this.telefono_movil.slice(0, 10);
            }
        },
    },
    created() {
        this.$store.state.title = "Mi Cuenta"
    },
    computed: {
        profile_image_path() {
            if (this.$store.state.user.foto != null) {
                return process.env.VUE_APP_PROFILE_IMG_PATH + this.$store.state.user.foto
            } else {
                return "/usuario.png"
            }
        },
        formattedBirthdate() {
            if (!this.birthdate) return '';
            return this.formatDate(this.birthdate);
        }
    },
    async mounted() {
        // Load Google Maps when component mounts
        await this.loadGoogleMapsScript();
    }
}
</script>

<style scoped>
.avatar_img {
    overflow: visible
}

.address-selection {
    margin: 20px 0;
}

.address-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.address-title {
    margin-left: 8px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
}
</style>
