<template>
    <v-col class="ma-0 pa-2">
        <!-- Botón agregar cita-->
        <v-row justify="center" class="mb-1 mt-0">
            <v-btn elevation="4" class="eps_green boton_eps" @click="open_appointment_form()">
                <v-icon class="pr-2">mdi-calendar</v-icon>Agendar cita
            </v-btn>
        </v-row>
        <!-- Mi calendario-->
          
        <v-row>
            <v-col>
                <!-- Ficha de cita-->
                <v-card class="mb-3" v-for="event in $store.state.appointments" :key="event.id">
                    <v-card-title class="d-flex align-center text-subtitle-1">
                        <v-icon left color="green">mdi-calendar-check</v-icon>
                        {{ event.service }}
                    </v-card-title>
                    <v-card-subtitle>
                        {{ event.date | date_format_short }} - {{ event.time }}
                    </v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col cols="4">
                                <v-img :src="`${vehicle_image_path}${event.vehicle_picture}`" height="100"
                                    contain></v-img>
                            </v-col>
                            <v-col cols="8">
                                <div class="d-flex align-center mb-0">
                                    <v-icon small class="mr-2">mdi-car</v-icon>
                                    <span>{{ event.vehicle_name }} - {{ event.vehicle_plates }}</span>
                                </div>
                                <div class="d-flex align-center mb-0">
                                    <v-icon small class="mr-2">mdi-calendar</v-icon>
                                    <span>{{ event.date | date_format_short }} - {{ event.time }}</span>
                                </div>
                                <div class="d-flex align-center mb-0">
                                    <v-icon small class="mr-2">mdi-store</v-icon>
                                    <span>EPS - {{ event.taller_name }}</span>
                                </div>
                                <div class="d-flex align-center mb-0">
                                    <v-icon small class="mr-2">mdi-map-marker</v-icon>
                                    <a :href="'https://www.google.com/maps/place/' + event.street_name + '+' + event.street_number + '+' + event.neighborhood + '+' + event.municipality + '+' + event.zipcode"
                                        target="_blank">
                                        Como llegar
                                    </a>
                                </div>
                                <div class="d-flex align-center mb-0">
                                    <v-icon small class="mr-2">mdi-phone</v-icon>
                                    <a :href="'tel:' + event.taller_phone">
                                        {{ event.taller_phone }}
                                    </a>
                                </div>
                                
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols=12>
                                <!-- Rating section -->
                                <div v-if="new Date(event.date) < Date.now()" class="mt-2 text-center align-center">
                                    <v-rating v-model="event.rating" color="amber" dense half-increments hover size="25"
                                        @change="update_appointment_debounced(event.id, event.rating, event.comments)"
                                        @input="update_appointment_debounced(event.id, event.rating, event.comments)"></v-rating>
                                    <v-text-field v-model="event.comments" dense rows="4"
                                        label="Comentarios sobre el servicio..."
                                        @input="update_appointment_debounced(event.id, event.rating, event.comments)"
                                        class="mt-3"></v-text-field>
                                </div>
                                <!-- Add Cancel button for future appointments -->
                                <div v-else class="text-center mt-2">
                                    <v-btn color="red" text @click="confirm_cancel_appointment(event.id)">
                                        <v-icon left>mdi-calendar-remove</v-icon>
                                        Cancelar cita
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <div>

            <!--Alert Erase Appointment-->
            <v-snackbar timeout="8000" v-model="alert_erase" class="pa-8">
                <h3 :color="alert_color">Confirmación:</h3>
                <strong>{{ alert_message }}</strong>
                {{ selected_appointment.id }}
                <p>{{ selected_appointment.vehicle_name }} - {{ selected_appointment.vehicle_plates }}</p>
                <p>{{ selected_appointment.taller_name }}</p>
                <p>{{ selected_appointment.service }}</p>
                <p>{{ selected_appointment.date | date_format_short }} - {{ selected_appointment.time }}</p>
                <v-col>
                    <v-row justify="end">
                        <v-btn text @click="alert_erase = false">No</v-btn>
                        <v-btn text :loading="erasing" @click="cancel_appointment()">Cancelar cita</v-btn>
                    </v-row>
                </v-col>
            </v-snackbar>
            <!--Alert Saved!-->
            <v-snackbar color="green" timeout="2000" v-model="alert_saved" class="pa-8">
                <h3>Gracias por calificarnos!</h3>
            </v-snackbar>
            <!--Crear cita-->
            <v-dialog v-model="show_create_appointment" :overlay="false" max-width="500px"
                transition="dialog-transition">
                <v-card>
                    <v-card-title primary-title>
                        <v-icon class="pr-3 pb-0">mdi-calendar</v-icon>
                        <h3>Agendar cita: </h3>
                    </v-card-title>
                    <v-card-text class="pa-8">
                        <v-form>
                            <v-select :items="$store.state.user_vehicles" item-text="name" item-value="id"
                                v-model="selected_vehicle" return-object label="Selecciona un vehículo"></v-select>
                            <v-select :items="service_types" item-text="name" item-value="id" item-icon="icon"
                                v-model="selected_service" return-object label="Selecciona un servicio"></v-select>
                            <v-select :items="shops" item-text="name" v-model="selected_shop" return-object
                                item-disabled="disabled" label="Selecciona un taller"></v-select>
                            <iframe class="field" v-if="selected_shop != ''" width="100%" height="180" style="border:1"
                                loading="lazy" allowfullscreen :src="map_url"></iframe>
                            <p v-if="selected_shop != ''"><strong>{{ selected_shop.name }}</strong> -
                                {{ selected_shop.street_name }}, {{ selected_shop.street_number }},
                                {{ selected_shop.neighborhood }}, {{ selected_shop.municipality }},
                                {{ selected_shop.zipcode }}, {{ selected_shop.state }}
                                <v-icon small>mdi-phone</v-icon><a :href="'Tel: ' + selected_shop.phone">{{
                                    selected_shop.phone }}</a>
                            </p>
                            <v-menu v-model="menu" :close-on-content-click="true" :nudge-right="0"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field prepend-icon="mdi-calendar" v-model="date" readonly v-bind="attrs"
                                        v-on="on" label="Fecha" />
                                </template>
                                <v-date-picker v-model="date" :reactive="false" label="Selecciona un servicio"
                                    :allowedDates="allowedDates" :locale="currentLocale" width="100%"></v-date-picker>
                            </v-menu>
                            <v-menu v-model="menu_time" :close-on-content-click="false" offset-y min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field prepend-icon="mdi-clock" v-model="time" readonly v-bind="attrs"
                                        v-on="on" label="Hora" />
                                </template>
                                <v-time-picker class="mt-3" ampm-in-title format="24hr" scrollable v-model="time"
                                    @input="menu_time = false" min="8:30" max="18:30"
                                    :allowed-minutes="allowedStep"></v-time-picker>
                            </v-menu>

                        </v-form>
                    </v-card-text>
                    <v-card-actions class="pa-8">
                        <v-spacer></v-spacer>
                        <v-btn elevation="4" @click="show_create_appointment = false" color="eps_red">Cancelar</v-btn>
                        <v-btn elevation="4" :loading="adding_appointment" @click="open_confirm_appointment()"
                            color="eps_green" :disabled="disable_add_appointment">Agendar cita</v-btn>
                        <p class="error_message">{{ add_appointment_error_message }}</p>
                    </v-card-actions>
                </v-card>

            </v-dialog>
            <!--Alert Confirmar cita-->
            <v-snackbar color="green" timeout="-1" max-width="500px" v-model="alert_confirm_appointment">
                <h3>Confirma tu cita:</h3>
                <br>
                <p><strong>Vehículo: </strong>{{ selected_vehicle.name }}</p>
                <p><strong>Servicio: </strong>{{ selected_service.name }}</p>
                <p><strong>Taller: </strong>{{ selected_shop.name }}</p>
                <p><strong>Dirección: </strong>{{ selected_shop.street_name }}, {{ selected_shop.street_number }},
                    {{ selected_shop.neighborhood }}, {{ selected_shop.municipality }}, {{ selected_shop.zipcode }}</p>
                <p><strong>Fecha: </strong>{{ date | date_format }}</p>
                <p><strong>Hora: </strong>{{ time }}</p>
                <v-col>
                    <v-row justify="end">
                        <v-btn class="eps_red ma-2" text :loading="erasing"
                            @click="alert_confirm_appointment = false">Modificar</v-btn>
                        <v-btn class="eps_green ma-2" text :loading="erasing" @click="add_appointment()">Agendar</v-btn>
                    </v-row>
                </v-col>
            </v-snackbar>
            <!-- Add this snackbar after the other snackbars -->
            <v-snackbar color="green" timeout="3000" v-model="appointment_created_alert">
                <h3>¡Cita agendada exitosamente!</h3>
            </v-snackbar>
        </div>
       
    </v-col>
</template>

<script>
import axios from "axios"

export default {
    name: 'MisEventos',
    data() {
        return {
            save_rating: false,
            alert_saved: false,
            alert_confirm_appointment: false,
            shops: [],
            date: "",
            time: "",
            currentLocale: "es-mx",
            adding_appointment: false,
            add_appointment_error_message: "",
            allowedStep: m => m % 10 === 0,
            allowedDates: val => new Date(val).getDay() != 6 && new Date(val) > Date.now(),
            apiurl: process.env.VUE_APP_ROOT_API,
            selected_service: "",
            selected_shop: "",
            selected_vehicle: "",
            rating: "",
            erasing: false,
            alert_erase: false,
            alert_message: "",
            alert_color: "",
            menu: false,
            menu_time: false,
            selected_appointment: {},
            panel: 0,
            show_create_appointment: false,
            event_types: [{
                id: 1,
                name: "Batería baja",
                icon: "mdi-car-battery"
            },
            {
                id: 2,
                name: "Sin gasolina",
                icon: "mdi-gas-station-off"
            },
            {
                id: 3,
                name: "Grúa",
                icon: "mdi-tow-truck"
            },
            {
                id: 4,
                name: "Servicios médicos",
                icon: "mdi-ambulance"
            },
            {
                id: 5,
                name: "Otro (Consulta cobertura)",
                icon: "mdi-chat-question-outline"
            }
            ],
            service_types: [{
                id: 1,
                name: "Mantenimiento Periódico",
                icon: "mdi-oil"
            },
            {
                id: 2,
                name: "Servicio Mayor",
                icon: "mdi-check-circle"
            },
            {
                id: 3,
                name: "Frenos",
                icon: "mdi-car-tire-alert"
            },
            {
                id: 4,
                name: "Amortiguadores",
                icon: "mdi-engine"
            },
            {
                id: 5,
                name: "Batería",
                icon: "mdi-car-electric-outline"
            },
            {
                id: 6,
                name: "Sistema de Enfriamiento",
                icon: "mdi-car"
            },
            {
                id: 7,
                name: "Llantas",
                icon: "mdi-car"
            },
            {
                id: 8,
                name: "Alineación y Balanceo",
                icon: "mdi-car"
            },
            {
                id: 9,
                name: "Revisión Autoprotégete",
                icon: "mdi-car"
            },
            {
                id: 10,
                name: "Otro",
                icon: "mdi-car"
            }

            ],
            headers: [{
                text: 'Vehículo',
                value: 'vehicle_id'
            },
            {
                text: 'Servicio',
                value: 'service'
            },
            {
                text: 'Fecha',
                value: 'date'
            },
            {
                text: 'Taller',
                value: 'taller_id'
            }
            ],
            calendarValue: new Date(),
            calendarType: 'month',
            calendarEvents: [],
            showEventDialog: false,
            selectedEvent: null,
            appointment_created_alert: false,
        }
    },

    methods: {
        update_event_debounced(event_id, event_rating, event_comments) {
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.update_event_rating(event_id, event_rating, event_comments)
            }, 1000)
        },
        update_appointment_debounced(appointment_id, appointment_rating, appointment_comments) {
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                const event = this.$store.state.appointments.find(app => app.id === appointment_id);
                if (event) {
                    this.update_appointment_rating(
                        appointment_id,
                        event.rating || appointment_rating,
                        event.comments || appointment_comments
                    )
                }
            }, 2000)
        },
        open_confirm_appointment() {
            this.alert_confirm_appointment = true
        },
        async open_appointment_form(vehicle_id) {
            this.selected_shop = ""
            this.time = null
            this.date = null
            this.selected_service = ""
            this.oppening_appointment_form = true
            this.selected_vehicle_id = vehicle_id
            try {
                let shops = await axios.get(process.env.VUE_APP_ROOT_API + '/api/shops/get_mieps_shops', {})
                if (shops.data.status == "ok") {
                    this.shops = shops.data.shops
                    this.show_create_appointment = true
                    this.oppening_appointment_form = false
                }
            } catch (err) {
                console.log(err)
            }
            this.show_create_appointment = true
            this.oppening_appointment_form = false
        },
        async add_appointment() {
            this.erasing = true
            this.add_appointment_error_message = ""
            this.adding_appointment = true
            try {
                const response = await axios.post(process.env.VUE_APP_ROOT_API + '/api/appointments/create', {
                    taller_id: this.selected_shop.id,
                    vehicle_id: this.selected_vehicle.id,
                    service: this.selected_service.name,
                    date: this.date,
                    time: this.time,
                    client_id: this.$store.state.user.id
                })
                if (response.data.status == "ok") {
                    this.get_appointments(this.$store.state.user.id)
                    this.adding_appointment = false
                    this.show_create_appointment = false
                    this.appointment_created_alert = true
                } else if (response.data.status == "error") {
                    this.add_appointment_error_message = response.data.error_message
                }
            } catch (err) {
                console.log(err)
            }
            this.alert_confirm_appointment = false
            this.erasing = false

        },
        async get_appointments(user_id) {
            try {
                let response = await axios.post(this.apiurl + '/api/appointments/get_appointments_by_user_id', {
                    user_id: user_id
                })
                if (response.data.status == "ok") {
                    this.$store.state.appointments = response.data.appointments
                    localStorage.setItem("appointments", JSON.stringify(response.data.appointments))
                }
            } catch (err) {
                console.log(err)
            }
        },
        async get_events(user_id) {
            try {
                let response = await axios.post(this.apiurl + '/api/appointments/get_events_by_user_id', {
                    user_id: user_id
                })
                if (response.data.status == "ok") {
                    this.$store.state.events = response.data.events
                    localStorage.setItem("events", JSON.stringify(response.data.events))
                }
            } catch (err) {
                console.log(err)
            }
        },
        async update_event_rating(event_id, event_rating, event_comments) {
            try {
                let response = await axios.post(this.apiurl + '/api/appointments/update_event_rating', {
                    event_id: event_id,
                    event_rating: event_rating,
                    event_comments: event_comments
                })
                this.alert_saved = true
                if (response) {
                    console.log(response)
                }
            } catch (err) {
                console.log(err)
            }
        },
        async update_appointment_rating(appointment_id, appointment_rating, appointment_comments) {
            try {
                let response = await axios.post(this.apiurl + '/api/appointments/update_appointment_rating', {
                    appointment_id: appointment_id,
                    appointment_rating: appointment_rating,
                    appointment_comments: appointment_comments
                })
                this.alert_saved = true
                if (response) {
                    console.log(response)
                }
            } catch (err) {
                console.log(err)
            }
        },
        confirm_cancel_appointment(id) {
            this.alert_erase = true
            this.alert_color = "red"
            this.alert_message = "¿Quieres borrar esta cita en el taller?"
            for (var i = 0; i < this.$store.state.appointments.length; i++) {
                if (id == this.$store.state.appointments[i].id) {
                    this.selected_appointment = this.$store.state.appointments[i]
                }
            }
        },
        async cancel_appointment() {
            this.erasing = true
            try {
                let response = await axios.post(process.env.VUE_APP_ROOT_API + '/api/appointments/cancel_appointment', {
                    appointment_id: this.selected_appointment.id
                })
                console.log(response)
                if (response.data.status == "ok") {
                    for (var i = 0; i < this.$store.state.appointments.length; i++) {
                        if (this.$store.state.appointments[i].id == this.selected_appointment.id) {
                            this.$store.state.appointments.splice(i, 1)
                            localStorage.setItem("appointments", JSON.stringify(this.$store.state.appointments))
                        }
                    }
                }
                this.alert_erase = false
                this.alert = true
                this.alert_color = "green"
                this.alert_message = response.data.message
                this.erasing = false
            } catch (err) {
                console.log(err)
            }
            this.alert_confirm = false
        },
        getEventColor(event) {
            console.log(event)
            return 'green'

        },
        showEvent({ event }) {
            this.selectedEvent = event;
            this.showEventDialog = true;
        },
        viewDay({ date }) {
            this.calendarValue = date
            this.calendarType = 'day'
        },
        updateCalendarEvents() {
            this.calendarEvents = this.$store.state.appointments.map(appointment => {
                // Parse date string to ensure consistent format
                let startDateTime;
                try {
                    // First try to parse the date directly
                    if (appointment.date && appointment.time) {
                        const [year, month, day] = appointment.date.split('-');
                        const [hours, minutes] = appointment.time.split(':');
                        startDateTime = new Date(year, month - 1, day, hours, minutes);
                        
                        // Validate the date is valid
                        if (isNaN(startDateTime.getTime())) {
                            throw new Error('Invalid date');
                        }
                    } else {
                        throw new Error('Missing date or time');
                    }
                } catch (error) {
                    console.warn('Invalid date format for appointment:', appointment);
                    startDateTime = new Date(); // Fallback to current date/time
                }

                const endDateTime = new Date(startDateTime.getTime() + 60 * 60 * 1000); // Add 1 hour

                return {
                    name: `${appointment.vehicle_name}`,
                    start: startDateTime,
                    end: endDateTime,
                    timed: true,
                    color: 'green',
                    details: appointment
                };
            });
        },
        returnToMonthView() {
            this.calendarType = 'month'
        },
        next() {
            this.$refs.calendar.next()
        },
        prev() {
            this.$refs.calendar.prev()
        },
        setToday() {
            this.calendarValue = new Date().toISOString().split('T')[0]
        },
    },
    mounted() {
        this.$store.state.appointments=[]
        if (this.$store.state.loggedin) {
            this.$store.state.title = "Mi Agenda"
            this.get_appointments(this.$store.state.user.id)
            if (localStorage.getItem('appointments')) {
                this.$store.state.appointments = JSON.parse(localStorage.getItem('appointments'))
            }
            if (localStorage.getItem('events')) {
                this.$store.state.events = JSON.parse(localStorage.getItem('events'))
            }
            this.$watch(
                () => this.$store.state.appointments,
                () => this.updateCalendarEvents(),
                { immediate: true }
            )
        }
    },
    computed: {
        vehicle_image_path() {
            return process.env.VUE_APP_VEHICLE_IMG_PATH
        },
        map_url() {
            var url = "https://www.google.com/maps/embed/v1/place?q=calle" + this.selected_shop.street_name + " " + this.selected_shop.street_number + " " + this.selected_shop.zipcode + "&key=AIzaSyDaKaJB_smN23gJGGpQShqOcuO5K44B97s"
            return url
        },
        disable_add_appointment() {
            if (this.time == "" || this.date == "" || this.selected_shop == "" || this.selected_vehicle == "" || this.selected_service == "") {
                return true
            } else {
                return false
            }
        },
        sortedAppointments() {
            return [...this.$store.state.appointments].sort((a, b) => {
                const dateA = new Date(`${a.date}T${a.time}`);
                const dateB = new Date(`${b.date}T${b.time}`);
                return dateB - dateA;
            });
        }
    }
};
</script>

<style scoped>
.header {
    font-size: .9em;
}

.v-expansion-panel-content>>>.v-expansion-panel-content__wrap {
    padding: 0 !important;
}

.bg {
    height: 100vmin;
    background-color: rgba(255, 255, 255, 0.1);
}

.custom-shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
}

.calendar-return-btn {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
}

.appointment-details-list {
    padding-left: 0;
    padding-right: 0;
}

.appointment-details-list :deep(.v-list-item) {
    min-height: 30px;
    /* Reduce from default 48px */
    padding: 0 1px;
    /* Reduce padding if needed */
}
</style>
