<template>
  <div class="tawk-container">
    <!-- Keep empty container -->
  </div>
</template>


<script>
export default {
  
    name: "MiAyuda",
    data:()=>({
    }),
    created(){
        if (this.$socket) {
            this.$socket.disconnect();
        }
        
        this.$store.state.title="Ayuda"
        // Remove any existing script to prevent duplicates
        const existingScript = document.querySelector('script[src*="tawk.to"]');
        if (existingScript) {
            existingScript.remove();
        }
        
        const script = document.createElement('script')
        script.async = true
        script.src = 'https://embed.tawk.to/677dd4caaf5bfec1dbe8252c/1ih1oedic'
        document.head.appendChild(script)
        
        // Show and maximize widget when component is created
        setTimeout(() => {
            if (window.Tawk_API) {
                // Set visitor name if available from store
                if (this.$store.state.user && this.$store.state.user.nombre) {
                    window.Tawk_API.setAttributes({
                        name: `${this.$store.state.user.nombre} ${this.$store.state.user.apellido_p || ''}`
                    });
                }
                window.Tawk_API.showWidget();
                window.Tawk_API.maximize();
            }
        }, 1000);
    },
    beforeDestroy() {
        if (this.$socket) {
            this.$socket.disconnect();
        }
        
        // Clean up Tawk.to when component is destroyed
        if (window.Tawk_API) {
            window.Tawk_API.hideWidget();
            window.Tawk_API.minimize();
        }
        
        // Remove the script tag
        const tawkScript = document.querySelector('script[src*="tawk.to"]');
        if (tawkScript) {
            tawkScript.remove();
        }
        
        // Remove any Tawk.to iframes and elements
        const tawkElements = document.querySelectorAll('[id^="tawk-"]');
        tawkElements.forEach(element => element.remove());
    }
}
</script>

<style>
/* Position chat within v-main */
#tawk-iframe-container {
    position: relative !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    height: 100% !important;
    width: 100% !important;
    max-height: none !important;
    max-width: none !important;
}

iframe#tawk-iframe-widget-main {
    position: relative !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    height: 100% !important;
    width: 100% !important;
    max-height: none !important;
    max-width: none !important;
}

/* Hide the floating minimize button */
.tawk-min-container {
    display: none !important;
}

.tawk-widget-container {
    position: relative !important;
    left: 100 !important;
    right: 0 !important;
    top: 100 !important;
    bottom: 0 !important;
    height: 100% !important;
    width: 100% !important;
    max-height: none !important;
    max-width: none !important;
}

.tawk-container {
    position: relative;
    height: 80%;
    width: 100%;
}
</style>



